const routes = [
    {
      index: true,
      label: "Ruyu Yan",
      path: '/',
    },
    {
      label: 'Projects',
      path: '/projects',
    },
    {
      label: 'MISC',
      path: '/misc',
    },
  ];
  
  export default routes;